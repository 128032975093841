import './App.css';
import HeaderAnimate from './Header-animate';
import 'bootstrap/dist/css/bootstrap.css';

const Header = () => {
    return (
        <>
            <div className="header-container">
                <div className="container heading-main">
                    <HeaderAnimate />
                </div>
            </div>
        </>
    );
}

export default Header;