import "./App.css";
import 'bootstrap/dist/css/bootstrap.css';
import ColumnOne from "./ColumnsPage/ColumnOne";
import ColumnTwo from "./ColumnsPage/ColumnTwo";
import ColumnThree from "./ColumnsPage/ColumnThree";
//import { useNavigate } from "react-router-dom";

const Column = () => {

    return (
        <div className="container">
            <div className="row colall row1">
            <ColumnOne />
            <ColumnTwo />
            <ColumnThree />
             </div>
        </div>
    );
}

export default Column;