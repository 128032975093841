import MainPage from "./MainPage";
import ColumnTwoResults from "./ColumnsPage/ColumnTwoResults";
import ColumnThreeResults from "./ColumnsPage/ColumnThreeResults";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ColumnOneResults from "./ColumnsPage/ColumnOneResults";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/columnthree/" element={<ColumnThreeResults />}>
          </Route>

          <Route path="/columntwo/" element={<ColumnTwoResults />}>
          </Route>

          <Route path="/columnone/" element={<ColumnOneResults />}>
          </Route>

          <Route path="/" element={<MainPage />}>
          </Route>
        </Routes>
      </Router>

    </div>
  );

}

export default App;
