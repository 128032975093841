import Header from "../Header";
import Footer from "../Footer";

const ColumnTwoResults = () => {
    return (
        <>
        <Header /> 
        <div className="container content-section col-colour">
            <div className="display-3 col-colour-two">UX Design and Branding</div>
            <p className="p-size">XhobbIt specialises in end-to-end website design and development. Our tried and tested approach to web design projects delivers great results again and again.</p>
        </div> 
        <Footer />
       </>
     );
}
 
export default ColumnTwoResults;