import Header from "./Header";
import Column from "./Column";
import Footer from "./Footer";

const MainPage = () => {
    return ( 
        <div>
            <Header />
            <Column />
            <Footer />
        </div>
     );
}
 
export default MainPage;