import Header from "../Header";
import Footer from "../Footer";

const ColumnOneResults = () => {
    
    return ( 
        <>
        <Header />
        <div className="container content-section col-colour">
            <div className="display-3 col-colour-two">Web and Software Development</div>
            <p className="p-size">We specialise in CMS development, CRM integration, API development and integrating with 3rd party systems. Whatever your software requirements, XhobbIt's web development team can help you plan, scope and develop your project.</p>
            </div>
        <Footer />
        </>
     );
}
 
export default ColumnOneResults;