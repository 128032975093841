import Header from "../Header";
import Footer from "../Footer";

const ColumnThreeResults = () => {
    return ( 
        <>
            <Header />
                <div className="container content-section col-colour">
                    <div className="display-3 col-colour-two">Digital Marketing</div>
                    <p className="p-size">Not only can we plan, design and develop your new website, we also help our clients build traffic and increase business, sales and conversions, post go-live.</p>
                </div>
            <Footer />
        </>
     );
}
 
export default ColumnThreeResults;