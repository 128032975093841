import { useNavigate } from "react-router-dom";

const ColumnOne = () => {
    const navigate = useNavigate();
    const onColumnChange = () => {
        navigate(`/columnone/`);
    }

    return (   
    <button onClick={onColumnChange} className="col-sm col-default col1 row1 ml-3">Web and Software <div></div>Development<div></div>
        <i className="material-icons icon">devices</i>
    </button> 
);
}
 
export default ColumnOne;