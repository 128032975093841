import { useNavigate } from "react-router-dom";

const ColumnThree = () => {
    const navigate = useNavigate();
    const onColumnChangeThree = () => {
        navigate(`/columnthree/`);
    }
  
    return ( 
        <button onClick={onColumnChangeThree} className="col-sm col-default col3 col-width row1">Digital <div></div>Marketing<div></div>
                    <i className="material-icons icon">attach_money</i>
                </button>
     );
}
 
export default ColumnThree;