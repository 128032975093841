import 'bootstrap/dist/css/bootstrap.css';

const HeaderAnimate = () => {
    return (
        <>
            <p className="intro lineUp border border-5 rounded-pill border-warning">We are Xhobbit</p>
            <p className="subtitle lineUp">Design <b className="colour-text">success</b> with <b className='colour-text'>creative</b> websites...</p>
        </>
    );
}

export default HeaderAnimate; 