import { useNavigate } from "react-router-dom";

const ColumnTwo = () => {

    const navigate = useNavigate();
    const onColumnChangeTwo = () => {
        navigate(`/columntwo/`);
    }

    return ( 
        <button onClick={onColumnChangeTwo} className="col-sm col-default col2 col-width row1">UX Design <div></div>and Branding<div></div>
                    <i className="material-icons icon">compare</i>
                </button>
     );
}
 
export default ColumnTwo;